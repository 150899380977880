@keyframes dots-pulse {
  0% {
    opacity: 0.1; }
  15% {
    opacity: 1; }
  100% {
    opacity: 0.1; } }
.rai-dots .rai-circle {
  border-radius: 100%;
  display: inline-block;
  margin-right: 0.1875em;
  background-color: #727981;
  width: 0.5em;
  height: 0.5em;
  animation-name: dots-pulse;
  animation-duration: inherit;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite; }
  .rai-dots .rai-circle:last-child {
    margin-right: 0; }
